<template>
	<div id="about-us">
        <v-head></v-head>
        <div class="container">
            <div class="banner">
                <h1 class="title">關於我們</h1>
            </div>
            <div class="content">
                <p>中醫在綫是領先的中醫診所管理互動平臺，致力於提供“互動、便捷、全面”的診所管理及醫患溝通的解決方案。</p>
                <p>中醫在綫從2013年建立開始，始終以“信任”作為產品和服務的核心。作為創新的中醫診所管理互動平臺，我們不僅從產品上確保用戶在綫管理的安全及方便，同時致力於通過互動溝通， 醫師統一帳號等創新讓用戶通過中醫在綫簡化日常診所管理程序及讓醫患在網絡間建立融洽的關系，去幫助建設更純凈更效率的互聯網中醫藥環境。</p>
                <p>2014年3月，中醫在綫推出人性化診所設定功能，用戶能夠按照自已需求為診所的診症，配藥及收費設置不同的使用細節，逹至最適合自己診所的工作模式。</p>
                <p>2014年10月起，中醫在綫實現了系統內溝通功能，診所所有人員能在綫上真正實現了隨時隨地的“即時溝通”，讓診所管理更為便捷，更以成熟的短訊通知解決方案為綫下用戶提供更加便捷完善的醫患咨訊服務。</p>
                <p>目前，中醫在綫已經跟多家診所及中醫師建立了深入的合作關系，成為中醫診所管理中最為信任的合作夥伴。 我們會跟各領域的合作夥伴一起，繼續圍繞用戶需求不斷創新。希望用我們的服務給您的工作及生活帶來微小而美好的改變。</p>
            </div>
        </div>
        <v-foot></v-foot>
	</div>
</template>

<script>
    import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
	export default {
		components: {
			vHead,
			vFoot
		}
	};
</script>

<style lang="scss" scoped>
    #about-us {
        width: 100%;
    }

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        align-items: center;
        justify-content: flex-start;
    }

    .banner {
        width: 100%;
        height: 146px;
        background-image: url(../../../assets/imgs/pc/aboutus_bannel@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .banner > .title {
        font-size: 42px;
        font-weight: bold;
        color: #231F20;
        margin-top: 45px;
        line-height: 42px;
        position: relative;
    }

    .banner > .title::before {
        content: '';
        position: absolute;
        left: -210px;
        top: 21px;
        width: 180px;
        height: 1px;
        background-color: #98E1E7;
    }

    .banner > .title::after {
        content: '';
        position: absolute;
        right: -210px;
        top: 21px;
        width: 180px;
        height: 1px;
        transform: translateY(-50%);
        background-color: #98E1E7;
    }

    .content {
        width: 1000px;
        margin: 58px auto 190px auto;
        color: #636363;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    .content > p {
        margin-top: 20px;
    }

    .content > p:nth-child(1) {
        margin-top: 0;
    }
    
    .content > p::first-letter {
        margin-left: 36px;
    }
</style>
